import React from 'react';
import styles from './styles.module.scss';
import {
  type ArbitraryTypedObject,
  type PortableTextBlock
} from '@portabletext/types';
import { BlogTile } from './BlogTile';
import { Helmet } from 'react-helmet';
import CustomSection from '../CustomSection/CustomSection';
import { Heading1, LeadParagraph, Link } from '@entur/typography';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as AktueltSvg } from '../../assets/decoration/aktuelt.svg';
import { useSanityPosts } from './useSanityPosts';

export interface SanityPost {
  alt: string;
  title: string;
  visible: boolean;
  _updatedAt: string;
  _id: string;
  slug: { current: string };
  excerpt: PortableTextBlock | ArbitraryTypedObject[] | PortableTextBlock[];
  body: PortableTextBlock | ArbitraryTypedObject[] | PortableTextBlock[];
  mainImage: string;
}

export function getParsedDate(date: string) {
  return new Date(date).toLocaleString('nb-NO', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
}

export function Blogg() {
  const isMobileScreen = useMediaQuery({ query: '(max-width: 767px)' });
  const sanityPosts = useSanityPosts();
  const synlgieSanityPosts = sanityPosts.filter((post) => post.visible);

  return (
    <>
      <Helmet>
        <title>Aktuelle saker | Samferdselsdata</title>
      </Helmet>
      <CustomSection className={styles.titleContainer}>
        <div>
          <Heading1>Bloggorama</Heading1>
          <LeadParagraph>
            Samarbeidet ønsker å tilrettelegge for erfaringsdeling og øke fokus
            på verdien av bruk og deling av data. derfor både deltar vi på og
            arrangerer en rekke møteplasser. Dersom du ønsker et varsel ved nye
            arrangementer kan du registrere deg{' '}
            <Link
              target={'_blank'}
              rel={'noreferrer'}
              href={
                'https://forms.office.com/Pages/ResponsePage.aspx?id=vpbBMGsQ0ESd9caS_gjTPdKXH4aMfb9FncWD75fW3h1UMzg2UktYM05GSzNNUkRETThSSVdRVENJTi4u'
              }
            >
              her
            </Link>
            .
          </LeadParagraph>
        </div>
        {!isMobileScreen && <AktueltSvg />}
      </CustomSection>
      <div className={styles.container}>
        <div className={styles.grid}>
          {synlgieSanityPosts
            .sort(
              (a, b) =>
                new Date(b._updatedAt).getTime() -
                new Date(a._updatedAt).getTime()
            )
            .map((post) => (
              <BlogTile key={post._id} post={post} />
            ))}
        </div>
      </div>
    </>
  );
}
