import { useEffect, useState } from 'react';
import { SanityPost } from './Blogg';
import { getSanityPosts } from '../../config/sanityClient';

export const useSanityPosts = () => {
  const [sanityPosts, setSanityPosts] = useState<SanityPost[]>(() => {
    const storedPosts = sessionStorage.getItem('sanityPosts');
    return storedPosts ? JSON.parse(storedPosts) : [];
  });

  useEffect(() => {
    getSanityPosts()
      .then((response) => {
        setSanityPosts(response);
        sessionStorage.setItem('sanityPosts', JSON.stringify(response));
      })
      .catch((error) => {
        console.error('Error fetching posts:', error);
      });
  }, []);

  return sanityPosts;
};
