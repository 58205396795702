import { createClient } from '@sanity/client';
// Import using ESM URL imports in environments that supports it:
// import {createClient} from 'https://esm.sh/@sanity/client'

export const client = createClient({
  projectId: '9cdnf2ci',
  dataset: 'production',
  useCdn: true, // set to `false` to bypass the edge cache
  apiVersion: '2023-05-03' // use current date (YYYY-MM-DD) to target the latest API version
});

const query = `*[_type == "post"]{
  slug,
  body,
  excerpt,
  alt,
  title,
  visible,
  _updatedAt,
  _id, 
  "mainImage": mainImage.asset->.url
}`;

// uses GROQ to query content: https://www.sanity.io/docs/groq
export async function getSanityPosts() {
  // @ts-ignore
  return await client.fetch(query);
}
