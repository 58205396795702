import React from 'react';
import './BlogPost.scss';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { getParsedDate } from '../Blogg/Blogg';
import { PortableText } from '@portabletext/react';
import { toPlainText } from '@portabletext/toolkit';
import { useSanityPosts } from '../Blogg/useSanityPosts';

export function BlogPost() {
  const { slug } = useParams();
  const sanityPosts = useSanityPosts();

  const sanityPost = sanityPosts.find((post) => post.slug.current === slug);

  if (!slug || !sanityPost) {
    return null;
  }

  const title = sanityPost.title;
  const excerpt = toPlainText(sanityPost.excerpt);
  const image = sanityPost.mainImage;
  const imageAlt = sanityPost.alt;

  return (
    <div className="blog-post">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={excerpt} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={excerpt} />
        <meta property="og:image" content={image} />
      </Helmet>
      <div>
        <header className="header">
          <h1 className="title">{title}</h1>
          <time className="date" dateTime={sanityPost._updatedAt}>
            {getParsedDate(sanityPost._updatedAt)}
          </time>
        </header>
        <figure className="image-container">
          <img src={image} alt={imageAlt} className="image" />
        </figure>
        <div className="content">
          <PortableText value={sanityPost.body} />
        </div>
      </div>
    </div>
  );
}
